import React, { useState } from 'react';

function VersionActionButtons({ version, handleVersions, handleActionButtons }) {

    if(version.is_active) {
        return null;
    }

    return (
        <div className="table-action">
        <a href="#" className="action_btn_dots" onClick={(e) => handleActionButtons(e)}></a>
        <div className="action_btns">
            <a href="#" className="action_btn action_btn_activate" data-event-id={version.ingest_ks_event_id} onClick={(e) => handleVersions(e, 'activate')}>Activate</a>
            <a href="#" className="action_btn action_btn_delete" data-event-id={version.ingest_ks_event_id} onClick={(e) => handleVersions(e, 'delete')}>Delete</a>
        </div>
        </div>
    );
}

export default VersionActionButtons;