import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Row, Col, Form } from "react-bootstrap";
import MainAppWrap from "../../layout/MainAppWrap";
import SimpleHeader from "../SimpleHeader";
import Accordion from "react-bootstrap/Accordion";
import ItemsList from "./partials/ItemsList";
import SettingsLoader from "../common/SettingsLoader";
import { convertDateIntoLocalDate } from "../common/Functions";
import { toast, ToastContainer } from "react-toastify";
import { set } from "date-fns";
import VersionsList from "./partials/VersionsList";
import { apiPath } from "../../config";
import UploadFile from "./partials/UploadFile";
import { Link } from "react-router-dom";
import KsDetailsHeader from "./ksdetails/KsDetailsHeader";
import ItemsListGrid from "./partials/ItemsListGrid";

function KsDetails() {
  const navigate = useNavigate();
  const { getIdTokenClaims, user } = useAuth0();
  const [ksDetails, setKsDetails] = useState([]);
  const { ks_id } = useParams();
  const [activeVersion, setActiveVersion] = useState("");

  const [isLoadingKS, setIsLoadingKS] = useState(true);

  // Add state variables for inline editing
  const [editingName, setEditingName] = useState(false);
  const [editingURL, setEditingURL] = useState(false);
  const [editingDescription, setEditingDescription] = useState(false);
  const [editingConfidential, setEditingConfidential] = useState(false);
  const [editingGithubOrg, setEditingGithubOrg] = useState(false);
  const [newName, setNewName] = useState("");
  const [newURL, setNewURL] = useState("");
  const [newDescription, setNewDescription] = useState("");
  const [newConfidential, setNewConfidential] = useState("");
  const [newGithubOrg, setNewGithubOrg] = useState("");
  const [isFileUploaded, setIsFileUploaded] = useState(false);

  const [showFileUpload, setShowFileUpload] = useState(false);

  const getKs = async () => {
    try {
      setIsLoadingKS(true);
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/knowledge_source/${ks_id}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log("ks details: ", result);
      // Assuming ksDetails.versions is an array of objects with a status property
      const activeVersion = result.versions.find(
        (version) => version.is_active === true
      );
      console.log("active version: ", activeVersion);
      setActiveVersion(activeVersion);
      setKsDetails(result);
      setNewName(result.name);
      setNewURL(result.url);
      setNewDescription(result.description);
      setNewConfidential(result.confidential);
      setNewGithubOrg(result.gihub_org);
      setIsLoadingKS(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoadingKS(false);
    }
  };

  const UpdateKs = async (data) => {
    try {
      const toastid = toast.loading("Please wait...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: data,
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/knowledge_source?ksid=${ks_id}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log(result);
      toast.update(toastid, {
        render: "Knowledge source updated successfully!",
        type: "success",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      const activeVersion = result.versions.find(
        (version) => version.is_active === true
      );
      setActiveVersion(activeVersion);
      setKsDetails(result);

      setEditingName(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInlineEdit = (type, value) => {
    // Function to validate URLs
    const isValidUrl = (url) => {
      // Regular expression for a valid URL
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
      return urlRegex.test(url);
    };

    const removeUrlParams = (url) => {
      const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/i;
      if (urlRegex.test(url)) {
        const urlObj = new URL(url);
        // Remove "https://" and trailing slashes from the origin
        const cleanedOrigin = urlObj.origin
          .replace(/^https?:\/\//, "")
          .replace(/\/+$/, "");
        return cleanedOrigin;
      }
      return url;
    };

    // e.target.classList.add("disabled");

    let data = {};
    if (type === "name") {
      data.name = value;
    }
    if (type === "description") {
      data.description = value;
    }
    if (type === "url") {
      if (isValidUrl(value)) {
        if (ksDetails.type === "website") {
          data.url_filter = data.url_filter || [];
          const filterUrl = removeUrlParams(value);
          data.url = value;
          data.url_filter.push(filterUrl);
        }
        if (ksDetails.type === "zendesk_knowledge_base") {
          data.zendesk_domain = value;
        }
      } else {
        toast.error("Invalid URL format!");
        return;
      }
    }
    if (type === "confidential") {
      data.confidential = value;
    }
    if (type === "github_org") {
      data.github_org = value;
    }
    data = JSON.stringify(data);
    // console.log(data);
    UpdateKs(data);
  };

  useEffect(() => {
    if (ksDetails.length === 0 && user) {
      getKs();
    }
  }, [user, ks_id]);

  const handleDeleteWarning = (e) => {
    e.preventDefault();
    document.getElementById("delete_user_modal-alert").classList.add("show");
  };
  const handleCancelDeleteKS = (e) => {
    e.preventDefault();
    document.getElementById("delete_user_modal-alert").classList.remove("show");
  };
  
  
  const handleDelete = async (e) => {
  
    e.preventDefault();
    if (e.target.classList.contains("disabled")) {
        return null;
    }
    e.target.classList.add("disabled");
    try {
        const toastid = toast.loading("Please wait...", {
            position: toast.POSITION.BOTTOM_RIGHT
            })
        // Get the ID token from Auth0
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
         const options = {
           method: 'DELETE',
           headers: {
             Authorization: `Bearer ${idToken}`,
             'Content-Type': 'application/json'
           },
         };
        const path = `/knowledge_source?ksid=${ks_id}`;
        const url = `${apiPath()}${path}`;
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        // console.log(result);
        toast.update(toastid, {
            render: "Knowledge source deleted successfully!",
            type: "success",
            isLoading: false,
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 3000
        });
        document.getElementById("delete_user_modal-alert").classList.remove("show");
        e.target.classList.remove("disabled");
        // Redirect to the knowledge sources page
        setTimeout(() => {
          navigate("/settings/knowledge-sources");
        }, 3200);
    } catch (error) {
        console.error("Error fetching data:", error);
    }
  };

  const handleVersions = async (e, type) => {
    try {
      let postBody = {};
      const toastid = toast.loading("Please wait...", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      if (type === "ingest"){
        postBody = JSON.stringify({
          ks_id: ks_id,
          action: "ingest",
        });
      }
      if(type === "delete"){
        postBody = JSON.stringify({
          ingest_ks_event_id: e.target.getAttribute("data-event-id"),
          action: "delete",
        });
      }
      if(type === "activate"){
        postBody = JSON.stringify({
          ingest_ks_event_id: e.target.getAttribute("data-event-id"),
          action: "activate",
        });
      }
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: postBody,
      };
      // console.log((await Auth.currentSession()).getIdToken().getJwtToken())
      const path = `/knowledge_source?ksid=${ks_id}`;
      const url = `${apiPath()}${path}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      // console.log(result);
      if(type === "ingest"){
        toast.update(toastid, {
          render: "Ingested!",
          type: "success",
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
      if(type === "delete"){
        toast.update(toastid, {
          render: "Version deleted!",
          type: "success",
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
      if(type === "activate"){
        toast.update(toastid, {
          render: "Version activated!",
          type: "success",
          isLoading: false,
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 3000,
        });
      }
      // console.log(result);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFileUploadPopup = (e) => {
    e.preventDefault();
    setShowFileUpload(!showFileUpload);
  }

  useEffect(() => {
    if (isFileUploaded) {
      setShowFileUpload(false);
    }
  }, [isFileUploaded]);

  return (
    <MainAppWrap>
      <div className="main-content mont-font response-detail-page">
        <SimpleHeader title="Knowledge Source Details" />
        <KsDetailsHeader
          ksDetails={ksDetails}
          editingName={editingName}
          setEditingName={setEditingName}
          newName={newName}
          setNewName={setNewName}
          handleInlineEdit={handleInlineEdit}
          editingURL={editingURL}
          setEditingURL={setEditingURL}
          newURL={newURL}
          setNewURL={setNewURL}
          editingDescription={editingDescription}
          setEditingDescription={setEditingDescription}
          newDescription={newDescription}
          setNewDescription={setNewDescription}
          editingConfidential={editingConfidential}
          setEditingConfidential={setEditingConfidential}
          newConfidential={newConfidential}
          setNewConfidential={setNewConfidential}
          editingGithubOrg={editingGithubOrg}
          setEditingGithubOrg={setEditingGithubOrg}
          newGithubOrg={newGithubOrg}
          setNewGithubOrg={setNewGithubOrg}
          handleDeleteWarning={handleDeleteWarning}
          isLoadingKS={isLoadingKS}
          handleFileUploadPopup={handleFileUploadPopup}
          activeVersion={activeVersion}
          convertDateIntoLocalDate={convertDateIntoLocalDate}
        />
        {showFileUpload && (
          <div className="file-upload-popup">
            <div className="file-upload-popup-overlay" onClick={handleFileUploadPopup}></div>
            <div className="file-upload-popup-inner">
            <div className="file-upload-popup-header">
              <h2>Upload</h2>
              <a href="#" className="close-file-popup" onClick={handleFileUploadPopup}>
              </a>
            </div>
            <UploadFile ks_id={ks_id} setIsFileUploaded={setIsFileUploaded} />
            </div>
          </div>
        )}
        <Container fluid="xxl" className="">
          <Row>
            <Col>
              <Accordion defaultActiveKey="2">
              {ksDetails.type !== "contributed_knowledge" && (
                <Accordion.Item eventKey="0">
                <Accordion.Header>Url Filter</Accordion.Header>
                  <Accordion.Body>
                  <div className="ks_details_box">
                    {!isLoadingKS && (
                      <>
                        <div className="url-filters">
                          <h2></h2>
                          <ul>
                            {ksDetails.url_filter &&
                              ksDetails.url_filter.map((filter, index) => (
                                <li key={index}>{filter}</li>
                              ))}
                          </ul>
                        </div>
                      </>
                    )}
                    {isLoadingKS && <SettingsLoader />}
                  </div>
                  </Accordion.Body>
                </Accordion.Item>
              )}
              {ksDetails.type !== "contributed_knowledge" && (
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Versions</Accordion.Header>
                  <Accordion.Body>
                    <VersionsList versions={ksDetails.versions} handleVersions={handleVersions} />
                  </Accordion.Body>
                </Accordion.Item>
              )} 

                <Accordion.Item eventKey="2">
                  <Accordion.Header>Items</Accordion.Header>
                  <Accordion.Body>
                    <ItemsList ks_id={ks_id} ks_type={ksDetails.type} isFileUploaded={isFileUploaded} setIsFileUploaded={setIsFileUploaded} />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Col>
          </Row>
        </Container>
      </div>
      <ToastContainer />
      <div id="delete_user_modal-alert" className="delete-user-modal">
            <div className="delete-user-modal-content">
                <div className="delete-user-modal-body">
                    <p>Are you sure you want to delete this Knowledge Source?</p>
                    <h5>{newName}</h5>
                </div>
                <div className="delete-user-modal-footer">
                    <a href="#" className="delete-user-modal-btn cancel" onClick={(e)=>handleCancelDeleteKS(e)}>Cancel</a>
                    <a href="#" className="delete-user-modal-btn done" onClick={(e)=>handleDelete(e)}>Delete</a>
                </div>
            </div>
        </div>
    </MainAppWrap>
  );
}

export default KsDetails;
