import React, { createContext, useEffect, useRef, useContext, useCallback, useState } from 'react';
import { websocketPath } from '../../config';
import { useAuth0 } from '@auth0/auth0-react';

// Create WebSocket Context
const WebSocketContext = createContext(null);

// Custom hook to use WebSocket Context
export const useWebSocket = () => useContext(WebSocketContext);

// WebSocket Provider Component
export const WebSocketProvider = ({ children }) => {
  const { getIdTokenClaims, user } = useAuth0();
  const socket = useRef(null);
  const listeners = useRef(new Set());
  const pingInterval = useRef(null);
  const reconnectTimeout = useRef(null);
  const idleTimer = useRef(null);
  const webSocketStatus = useRef(null);

  const [error, setError] = useState(null);
  const [isIdle, setIsIdle] = useState(false);
  const idleTimeout = 60000; // 1 minute of inactivity

  // Add listener callback
  const addListener = useCallback((callback) => {
    listeners.current.add(callback);
  }, []);

  // Remove listener callback
  const removeListener = useCallback((callback) => {
    listeners.current.delete(callback);
  }, []);

  const connectWebSocket = useCallback(async () => {
    if(!user){
      return;
    }
    try {
      const tokenClaims = await getIdTokenClaims();
      const idToken = tokenClaims?.__raw;

      if (!idToken) {
        console.error("ID token is not available.");
        return;
      }

      const websocket_url = `${websocketPath()}?access_token=${idToken}&user_access_token=${idToken}&client_key=ODY8mXQS82`;

      socket.current = new WebSocket(websocket_url);

      // WebSocket event listeners
      socket.current.onopen = () => {
        console.log("WebSocket connected");
        webSocketStatus.current = 'open';
        setError(null);
        if (!isIdle) startPing(); // Start ping only if not idle
      };

      socket.current.onmessage = (event) => {
        listeners.current.forEach((listener) => listener(event.data));
      };

      socket.current.onclose = () => {
        console.warn("WebSocket closed. Reconnecting...");
        webSocketStatus.current = 'closed';
        stopPing();
        attemptReconnect();
      };

      socket.current.onerror = (e) => {
        console.error("WebSocket error:", e);
        setError("WebSocket connection error");
        webSocketStatus.current = 'error';
        socket.current.close();
      };
    } catch (err) {
      console.error("Error connecting WebSocket:", err);
      setError("Error connecting to WebSocket");
    }
  }, [user, isIdle]);

  const startPing = useCallback(() => {
    pingInterval.current = setInterval(() => {
      if (socket.current?.readyState === WebSocket.OPEN) {
        socket.current.send(JSON.stringify({ action: 'ping' }));
      }
    }, 30000);
  }, []);

  const stopPing = useCallback(() => {
    clearInterval(pingInterval.current);
    pingInterval.current = null;
  }, []);

  const attemptReconnect = useCallback(() => {
    reconnectTimeout.current = setTimeout(() => {
      console.log("Attempting to reconnect...");
      connectWebSocket();
    }, 5000);
  }, [connectWebSocket]);

  const handleUserActivity = useCallback(() => {
    setIsIdle(false);
    clearTimeout(idleTimer.current);
    idleTimer.current = setTimeout(() => {
      setIsIdle(true);
      stopPing();
    }, idleTimeout);
  }, [idleTimeout, stopPing]);

  const handleVisibilityChange = useCallback(() => {
    if (!document.hidden && webSocketStatus.current !== 'open') {
      console.log("Tab visible, reconnecting WebSocket...");
      connectWebSocket();
    }
  }, [connectWebSocket]);

  const cleanup = useCallback(() => {
    stopPing();
    clearTimeout(reconnectTimeout.current);
    clearTimeout(idleTimer.current);
  }, [stopPing]);

  useEffect(() => {
    connectWebSocket();

    // Attach user activity and visibility listeners
    if (typeof document !== 'undefined') {
      document.addEventListener('visibilitychange', handleVisibilityChange);
      document.addEventListener('mousemove', handleUserActivity);
      document.addEventListener('keydown', handleUserActivity);
    }

    return () => {
      socket.current?.close();
      cleanup();

      if (typeof document !== 'undefined') {
        document.removeEventListener('visibilitychange', handleVisibilityChange);
        document.removeEventListener('mousemove', handleUserActivity);
        document.removeEventListener('keydown', handleUserActivity);
      }
    };
  }, [connectWebSocket, handleVisibilityChange, handleUserActivity, cleanup]);

  const sendMessage = (message) => {
    if (socket.current?.readyState === WebSocket.OPEN) {
      socket.current.send(message);
    } else {
      console.warn("Cannot send message, WebSocket is not open.");
    }
  };

  const showError = () =>
    error && (
      <div>
        <p>Error: {error}</p>
        <button onClick={() => window.location.reload()}>Refresh Page</button>
      </div>
    );

  return (
    <WebSocketContext.Provider value={{ sendMessage, addListener, removeListener, webSocketStatus }}>
      {children}
      {showError()}
    </WebSocketContext.Provider>
  );
};
