import React, { useEffect, useState } from "react";

import { useWebSocket } from "../common/WebsocketContext";

import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Image from "react-bootstrap/Image";
import { Col, Row } from "react-bootstrap";
import SubmitIcon from "../../images/v2/submit-icon.svg";
import UserImg from "../../images/v2/user-avatar.svg";
import UserImg2 from "../../images/v2/ai-avatar.svg";
import DOMPurify from "dompurify";
import { marked } from "marked";
import FeedbackAndCopy from "../askquestion/FeedbackAndCopy";

import SettingsLoader from "../common/SettingsLoader";

import "./css/DirectChat.css";

const DirectChat = ({ element_id, question, overlay, top, left, handleChatClose }) => {
  const { sendMessage, addListener, removeListener, webSocketStatus } = useWebSocket();
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [conversation_id, setConversation_id] = useState('');
  const [response_id, setResponse_id] = useState('');
  const [output_format, setOutput_format] = useState('');
  const [currentStreamId, setCurrentStreamId] = useState(null);
  const [lastMessage, setLastMessage] = useState('');
  const [isUserScroll, setIsUserScroll] = useState(true);
  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);
  const [isChatWindowLoading, setIsChatWindowLoading] = useState(true);
  const [isConnected, setIsConnected] = useState(true);
    const [isShowing, setIsShowing] = useState(false);


  const [defaultQuestionBool, setDefaultQustionBool] = useState(true);

  const [isExpand, setIsExpanded] = useState(false);
  const handleExpanded = (e) => {
    e.preventDefault();
    handleSendMessage(inputMessage)
  };


  useEffect(() => {
    let response_id = '';
    let output_format = '';
    const handleMessage = (message) => {
      const response = JSON.parse(message);
      if (response.identifier === element_id + '-chat' || response.feedback?.identifier === element_id + '-chat') {
        // console.log(response);
        if (response.conversation_id) {
          setConversation_id(response.conversation_id);
          // console.log("conversation ID:", response.conversation_id);
        }


        if (response.response_id) {
          response_id = response.response_id;
          setResponse_id(response_id);
          // console.log("response ID:", response_id);
        }

        if (response.output_format) {
          output_format = response.output_format;
          // console.log("output_format:", output_format);
        }

        if (response && response.text) {
          setMessages(prevMessages => {
            const sender = 'ept AI';
            const messageIndex = prevMessages.findIndex(m => m.response_id === response_id && m.sender === sender);

            if (messageIndex !== -1) {
              const updatedMessages = [...prevMessages];
              const updatedMessage = {
                ...updatedMessages[messageIndex],
                text: updatedMessages[messageIndex].text + response.text,
                response_id: response_id // Use the ref's current value
              };
              updatedMessages[messageIndex] = updatedMessage;
              return updatedMessages;
            } else {
              return [
                ...prevMessages,
                { text: response.text, sender: sender, streamId: response.streamId, response_id: response_id, isStreamTerminated: false, output_format: output_format !== '' ? output_format : 'markdown' }
              ];
            }
          });
        }

        if (response.action === "terminate_stream") {
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.response_id === response_id
                ? { ...msg, isStreamTerminated: true }
                : msg
            )
          );
          setIsChatLoading(false);
          setIsSendButtonDisabled(false);
          document.getElementById(element_id + '-chat-input').focus();
          console.log("Stream terminated");
        }

        if (response.isFinalPart) {
          setCurrentStreamId(null);
        }

        if (response.feedback) {
          console.log("Feedback received:", response.feedback);
          setMessages((prevMessages) =>
            prevMessages.map((msg) =>
              msg.response_id === response.feedback.response_id
                ? { ...msg, feedback: response.feedback }
                : msg
            )
          );
        }
      }
    };
    addListener(handleMessage);

    return () => {
      removeListener(handleMessage);
    };
  }, [addListener, removeListener]);


  const MAX_RETRY_COUNT = 5; // Retry limit
  let retryCount = 0; // Track retry attempts
  let lastQuetionMessage = '';
  let lastQuestionMessageDefault = question;
  const handleSendMessage = (message) => {
    let finalMessage = message;
    // check if the same message is being sent again
    if (lastQuetionMessage === lastQuestionMessageDefault && retryCount === 0) {
      console.log("Same message is being sent again, skipping...");
      return;
    }
    lastQuetionMessage = finalMessage;
    const chatBox = document.getElementById(element_id + '-chat-messages-wrap-id');
    if(isChatWindowLoading) {
        setIsChatWindowLoading(false);
    }
    setTimeout(() => {
      chatBox.scrollTop = chatBox.scrollHeight;
    }, 0);

    if (webSocketStatus.current === 'open') {
      setIsExpanded(true);
      setIsConnected(true);
      setIsChatWindowLoading(false);
      retryCount = 0; // Reset retry count on success
      finalMessage = (typeof message === 'string' ? message.trim() : '') || inputMessage.trim();

      if (!finalMessage) {
        console.log("No message to send");
        return;
      }

      setDefaultQustionBool(false);
      setIsChatLoading(true);
      setIsSendButtonDisabled(true);

      let dataObject = {
        message: finalMessage,
        action: conversation_id === '' ? 'start_conversation' : 'conversation_message',
        identifier: element_id + '-chat',
      };

      if (conversation_id === '') {
        // dataObject.auth0_token = userToken;
        // dataObject.user_id = user.sub;
      } else {
        dataObject.conversation_id = conversation_id;
        dataObject.response_id = response_id;
      }

      sendMessage(JSON.stringify(dataObject));
      setMessages(prevMessages => [...prevMessages, { text: finalMessage, sender: 'user' }]);
      setTimeout(() => {
        lastQuestionMessageDefault = '';
        }, 100);
      if (inputMessage) {
        setInputMessage('');

      }
    } else {
      if (retryCount < MAX_RETRY_COUNT) {
        retryCount++;
        console.log(`WebSocket not open, retrying (${retryCount}/${MAX_RETRY_COUNT})...`);
        setIsConnected(false);
        setTimeout(() => {
          handleSendMessage(finalMessage);
        }, 5000); // Retry after 5 seconds
      } else {
        console.log("Max retry attempts reached. Failed to send the message.");
        setIsSendButtonDisabled(false); // Optionally re-enable the send button
      }
    }
  };

  const handleSendFeedback = (response_id, feedback) => {
    if (webSocketStatus.current === 'open') {
        sendMessage(JSON.stringify({
            "action": "feedback",
            "is_positive": feedback,
            "response_id": response_id,
            "user_id": 'unknown',
            "identifier": element_id + '-chat'
        }));
    } else {
        console.error("WebSocket not open. Failed to send feedback.");
    }
};

  const cleanHtmlContent = (html, output_format) => {
    let cleanhtml = '';
    if (output_format === 'markdown') {
      cleanhtml = marked(html);
    } else {
      cleanhtml = html;
    }

    let sanitizedHtml = DOMPurify.sanitize(cleanhtml, { USE_PROFILES: { html: true } });

    // Enhanced to remove language specifier along with code block delimiters
    // This will remove instances like ```html or ```javascript along with the triple backticks
    sanitizedHtml = sanitizedHtml.replace(/```(\w+)?\s?/g, '');

    // Find anchor links and add target="_blank" attribute
    sanitizedHtml = sanitizedHtml.replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*?)>/gi, (match, href, rest) => {
      // Check if the link already has a target attribute
      if (!rest.includes('target=')) {
        return `<a href="${href}" target="_blank"${rest}>`;
      } else {
        return match; // Link already has a target attribute
      }
    });

    return sanitizedHtml;
  };

  useEffect(() => {
    const chatBox = document.getElementById(element_id + '-chat-messages-wrap-id');
  
    const handleChatBoxScroll = () => {
      const isNearBottom =
        chatBox.scrollHeight - chatBox.scrollTop - chatBox.clientHeight < 10; // 50px buffer to allow slight offset
  
      setAutoScrollEnabled(isNearBottom);
    };
  
    chatBox.addEventListener('scroll', handleChatBoxScroll);
  
    return () => {
      chatBox.removeEventListener('scroll', handleChatBoxScroll);
    };
  }, [element_id]);

  useEffect(() => {
    const chatBox = document.getElementById(element_id + '-chat-messages-wrap-id');
  
    if (autoScrollEnabled) {
      // Scroll to the bottom only if auto-scroll is enabled
      chatBox.scrollTop = chatBox.scrollHeight;
    }
  }, [messages, autoScrollEnabled]);
  

  useEffect(() => {
    if(question === '' || question == undefined) return;
        setTimeout(() => {
            handleSendMessage(question);
        },250);
  },[question]);

  useEffect(() => {
    if (overlay) {
      const timer = setTimeout(() => {
        setIsShowing(true);
      }, 250);

      return () => clearTimeout(timer); // Cleanup if overlay changes
    } else {
      setIsShowing(false);
    }
  }, [overlay]);


  return (
    <div className={`${overlay ? 'fixed-overlay-chat' : ''}`}
    style={{ top: top !== undefined ? top: '', left: left !== undefined ? left: '' }}
    >
    {overlay && overlay !== undefined && <div className={`overlay-closer ${isShowing ? 'showing' : ''}`} onClick={handleChatClose}></div>}
    <div className={`d-flex flex-row justify-content-center fixed-chat-box ${overlay ? 'fixed-bottom-right' : ''}`}
    >
      <div className={`chat-wrap ${isExpand ? "expanded" : ""} ${(overlay !== undefined && overlay === true) ? ' expanded' : ''}`}>
        <h2 className="text-center text-dark">Ask me about ept AI!</h2>
        <div id={`${element_id}-chat-messages-wrap-id`} className="message-response-wrap">
          {/* {question && (
            <>
              <div className="message-box user-message">
                <div className="user-img">
                  <Image src={UserImg} />
                </div>
                <div>
                  <div>
                    <p>{question}</p>

                  </div>
                </div>
              </div>
              <div className="message-box bot-message">
                <div className="user-img">
                  <Image src={UserImg2} />
                </div>
                <div>
                  <div dangerouslySetInnerHTML={{ __html: cleanHtmlContent(canned_answer, "markdown") }} />

                </div>
              </div>
              {prompt_questions && prompt_questions.length > 0 && (
                <div className="prompt-questions">
                  <ul>
                    {prompt_questions.map((prompt, index) => (
                      <li key={index} onClick={() => handleSendMessage(prompt)}>{prompt}</li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )} */}
          {messages.map((message, index) => (
            <Col key={index} xs={12} className={`${message.sender === 'user' ? 'text-end' : 'text-start'}`}>
              <div className={`message-box ${message.sender !== 'user' ? 'bot' : 'user'}-message`}>
                <div className="user-img">
                  <Image src={message.sender === 'user' ? UserImg : UserImg2} />
                </div>
                <div id={message.response_id} dangerouslySetInnerHTML={{ __html: cleanHtmlContent(message.text, message.output_format) }} />
                {message.sender === 'ept AI' && message.isStreamTerminated && (
                    <FeedbackAndCopy
                    response_id={message.response_id}
                    sendFeedback={handleSendFeedback}
                    feedback={message.feedback}
                    />
                )}
              </div>
            </Col>
          ))}
          {isChatLoading && <SettingsLoader />}
        </div>
        <Form className="chat-form">
          <Form.Control
            className="form-text-field"
            type="text"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
            placeholder="Question about ept AI"
            id={`${element_id}-chat-input`}
          />
          <Button
            variant="primary"
            type="submit"
            className="submit-btn"
            disabled={isSendButtonDisabled}
            onClick={handleExpanded}
          >
            <Image src={SubmitIcon} rounded />
          </Button>
        </Form>
        {!isConnected && (
          <div className="connecting-text">Connecting...</div>
        )}
      </div>
    </div>
    </div>
  );
};

export default DirectChat;
