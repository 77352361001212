import React, {useEffect} from "react";
import Table from "react-bootstrap/Table";
import SettingsLoader from "../../common/SettingsLoader";
import {convertDateIntoLocalDate} from "../../common/Functions";

import { Grid, html, h } from "gridjs";
import VersionActionButtons from "./sub_partials/VersionActionButtons";
import { _ } from "gridjs-react";


function VersionsList(props) {
  const gridRef = React.useRef(null);

    const handleActionButtons = (e) => {
        e.preventDefault();
        let ele = e.target;
        let action_btns = ele.nextElementSibling;

        let other_action_btns = document.querySelectorAll('.action_btns');
        other_action_btns.forEach(function (ele) {
                ele.style.display = 'none';
            });
    
        // Check if the display property is either "none" or not set (empty string).
        if (action_btns.style.display === "block") {
            action_btns.style.display = "none";
        } else {
            // If it's "none" or empty, set it to "block"
            action_btns.style.display = "block";
        }
    };

    const transformString = (inputString) => {
        // Split the string by underscores, capitalize each word, and join them with spaces
        return inputString
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };

      const initializeGrid = async () => {
  
        try {
          // Destroy existing Grid.js instance if it exists
          if (gridRef.current) {
            gridRef.current.destroy();
            gridRef.current = null; // Reset the grid reference
          }
      
          // Initialize the Grid.js table
          const grid = new Grid({
            columns: [
              {
                id: 'ingest_ks_event_id',
                name: html(`<span style="max-width:300px; width:300px !important; display:block;">Name</span>`),
                formatter: (cell) => html(`<span class="">${cell}</span>`),
              },
              {
                id: 'is_active',
                name: 'Status',
                formatter: (cell) => html(`<span class="details-status">${cell}</span>`),
              },
              {
                id: 'ingest_date',
                name: 'Ingest Date',
              },
              {
                id: 'stats',
                name: 'Stats',
              },
              {
                id: 'action',
                name: 'Action',
              },
            ],
            data: props.versions.map((version, index) => {
              return [
                version.ingest_ks_event_id,
                version.is_active ? 'Active' : '',
                convertDateIntoLocalDate(version.ingest_date),
                Object.entries(version.stats)
                  .map(([key, value]) => (
                    key === "updated_at" ? (
                      `<b>${transformString(key)}:</b> ${new Date(value).toLocaleString()}`
                    ) : (
                      `<b>${transformString(key)}:</b> ${value.toLocaleString()}`
                    )
                  )).join('<br>'),
                _(<VersionActionButtons 
                version={version} 
                handleActionButtons={handleActionButtons}  
                handleVersions={props.handleVersions}
                />)
              ];
            }),
          });

          // Render the Grid.js table
          grid.render(document.getElementById('versions-table'));
          gridRef.current = grid;
        } catch (error) {
          console.error('Error initializing Grid.js table:', error);
        }
      }
      useEffect(() => {
        initializeGrid();
      }, [props.versions]);
  return (
    <div className="sources-wrap versions-wrap">
       <div id="versions-table"></div>
    </div>
  );
}

export default VersionsList;