import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Tab, Tabs } from "react-bootstrap";
import { useAuth0 } from '@auth0/auth0-react';
import SimpleHeader from "../components/SimpleHeader";
import Footer from "../components/Footer";
import QuestionHero from "../components/askquestion/QuestionHero";
import MainAppWrap from "../layout/MainAppWrap";
import FeedbackAndCopy from '../components/askquestion/FeedbackAndCopy';
import useAccessToken  from '../components/askquestion/useAccessToken';
import DOMPurify from 'dompurify';
import ept_bot_img from '../images/ept_bot.svg'
import Loader from '../components/common/Loader';
import LoaderWithText from '../components/common/LoaderWithText';
import { useForumList } from '../components/common/ForumListContext';
import { websocketPath } from '../config';
import {marked}  from "marked";
import { is } from 'date-fns/locale';
import { useWebSocket } from '../components/common/WebsocketContext';
// import ReactDOMServer from 'react-dom/server';

// import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'; 

const AskQuestionEptChat = () => {
  const { getIdTokenClaims, isLoading, user, isAuthenticated } = useAuth0();
  const { sendMessage, addListener, removeListener, webSocketStatus } = useWebSocket();
  const {eptChannels} = useForumList();
  const [eptClientID, setEptClientID] = useState('ODY8mXQS82');
  // const accessToken = useAccessToken(eptClientID);
  const [inputMessage, setInputMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const websocketRef = useRef(null);
  const [conversation_id, setConversation_id] = useState('');
  const [response_id, setResponse_id] = useState(0);
  const [isSendButtonDisabled, setIsSendButtonDisabled] = useState(false);
  const [isChatLoading, setIsChatLoading] = useState(false); // Updated state for chat loading
  const [connectionError, setConnectionError] = useState(false);
  const [connectionErrorMessage, setConnectionErrorMessage] = useState('');
  
  const [currentStreamId, setCurrentStreamId] = useState(null);
  const [chatLoadingText, setChatLoadingText] = useState([
    "Establishing secure connection...",
    "Initializing chat...",
    "Loading...",
    "Please wait...",
  ]);
  const [reconnectionLoading, setReconnectionLoading] = useState(false);
  const [currentDisplayText, setCurrentDisplayText] = useState('');
  const [isChatWindowLoading, setIsChatWindowLoading] = useState(false);
  const [defaultQustionBool, setDefaultQustionBool] = useState(true);

  const [selectedChannel, setSelectedChannel] = useState('');

  const [userToken, setUserToken] = useState('');


  const [autoScrollEnabled, setAutoScrollEnabled] = useState(true);

  const [isUserScroll, setIsUserScroll] = useState(true);

  const [noAccessMesasge, setNoAccessMesasge] = useState('');
  const [switchingForum, setSwitchingForum] = useState(false);

  const [lastMessage, setLastMessage] = useState('');
  

  let isWebsocketIdle = true;

  let lastActiveTime = Date.now();

  const PING_INTERVAL = 5000;

  const IDLE_TIMEOUT = 15 * 60 * 1000; // 15 minutes

  let pingInterval = null;

  let pendingFeedback = [];

    useEffect(() => {
        if (eptChannels.length > 0 && selectedChannel === '') {
        // console.log(kscs[0].ksc_id);
        // console.log(eptChannels);
        if(eptChannels === "access_denied"){
          setSelectedChannel("access_denied");
        }else {
          setSelectedChannel(
            {
              "channel":  eptChannels[0].channel,
              "name":  eptChannels[0].name,
              "url": eptChannels[0].url
            }
          );
        }
        }
    }, [eptChannels]);

  

  useEffect(() => {
    if (isLoading || !user ) {
      return;
    }
    if(selectedChannel === "access_denied"){
      console.log("Access Denied");
      setNoAccessMesasge("You don't have access to this feature. Please contact your administrator.");
      return;
    }
    // console.log('accessToken-', accessToken);
      // if ( selectedChannel !== '' ) {
        
      //   connectWebSocket();
      // }
  }, [isLoading, user, selectedChannel]);



  useEffect(() => {
    let response_id = 0;  
    const handleMessage = (message) => {
        let data = JSON.parse(message);
        if(data.identifier === selectedChannel.channel + '-' + "chat"){
        // console.log(response);
        if(data.conversation_id){
          setConversation_id(data.conversation_id);
          isWebsocketIdle = false;
          // console.log("conversation ID:", response.conversation_id);
        }
        

        if (data.response_id) {
          isWebsocketIdle = false;
          response_id = data.response_id;
          setResponse_id(response_id);
          // console.log("response ID:", response_id);
        }

        if (data.output_format) {
          output_format = data.output_format;
          // console.log("output_format:", output_format);
        }
  
        if (data && data.text) {
          setMessages(prevMessages => {
            const sender = 'ept AI';
            const messageIndex = prevMessages.findIndex(m => m.response_id === response_id && m.sender === sender);
  
            if (messageIndex !== -1) {
              const updatedMessages = [...prevMessages];
              const updatedMessage = {
                ...updatedMessages[messageIndex],
                text: updatedMessages[messageIndex].text + response.text,
                response_id: response_id // Use the ref's current value
              };
              updatedMessages[messageIndex] = updatedMessage;
              return updatedMessages;
            } else {
              return [
                ...prevMessages,
                { text: data.text, sender: sender, streamId: data.streamId, response_id: response_id, isStreamTerminated: false, output_format: output_format}
              ];
            }
          });
        }
  
        if (data.action === "terminate_stream") {
          // setResponse_id(prev => prev + 1); // Correctly increment the response_id
          setMessages(prevMessages =>
            prevMessages.map(msg =>
              msg.response_id === response_id ? { ...msg, isStreamTerminated: true } : msg
            )
          );
          setIsChatLoading(false);
          setIsSendButtonDisabled(false);
          document.querySelector('textarea').focus();
          console.log("Stream terminated");
        }

        if(data.error == "Stream has been idle for too long"){
          // resend the last message
          console.log("retrying last message");
          setTimeout(() => {
            sendMessageHanlde(lastMessage);
          },2000);
        }
  
        if (data.isFinalPart) {
          setCurrentStreamId(null);
        }

        if (data.feedback) {
          console.log('Feedback received:', data.feedback);
          setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                  msg.response_id === data.feedback.response_id ? { ...msg, feedback: data.feedback } : msg
              )
          );
        }
      }
    };
    addListener(handleMessage);

    return () => {
        removeListener(handleMessage);
    };
}, [addListener, removeListener]);
  

    
  
  // const connectWebSocket = async () => {
  //   try {
  //     setDefaultQustionBool(false);
  //     setIsSendButtonDisabled(true);
  //     const tokenClaims = await getIdTokenClaims();
  //     const idToken = tokenClaims.__raw;
  //     // console.log('idToken-', idToken);

  //     if (!idToken) {
  //       console.error("ID token is not available.");
  //       return;
  //     }
  //     // console.log('selectedChannel-', selectedChannel);
  //     const getWebsocketURL = websocketPath();
  //     const websocketUrl = `${getWebsocketURL}?access_token=${idToken}&user_access_token=${idToken}&client_key=${eptClientID}&channel_id=${selectedChannel.channel}`;
  //     // console.log('websocketUrl-', websocketUrl);
  //     const ws = new WebSocket(websocketUrl);

  //     ws.onopen = () => {
  //       setReconnectionLoading(false);
  //       setIsChatWindowLoading(false);
  //       setIsSendButtonDisabled(false);
  //       setSwitchingForum(false);
  //       if(pendingFeedback.length > 0){
  //         resendPendingFeedback();
  //       }
  //       if(messages.length === 0){
  //         setDefaultQustionBool(true);
  //       }
  //       // auto focus on text area when chat window is loaded
  //       document.querySelector('textarea').focus();
  //       console.log("WebSocket connection established");
  //       if (!pingInterval) {
  //         console.log('Setting up ping interval');
  //         pingInterval = setInterval(pingPong, PING_INTERVAL);
  //       }
      
  //       // Log interval ID for debugging
  //       console.log('Ping Interval ID:', pingInterval);
  //     };
  //     let response_id = '';
  //     let output_format = '';
  //     ws.onmessage = (event) => {
  //       const response = JSON.parse(event.data);
  //       // console.log(response);
  //       if(response.conversation_id){
  //         setConversation_id(response.conversation_id);
  //         isWebsocketIdle = false;
  //         // console.log("conversation ID:", response.conversation_id);
  //       }
        

  //       if (response.response_id) {
  //         isWebsocketIdle = false;
  //         response_id = response.response_id;
  //         setResponse_id(response_id);
  //         // console.log("response ID:", response_id);
  //       }

  //       if (response.output_format) {
  //         output_format = response.output_format;
  //         // console.log("output_format:", output_format);
  //       }
  
  //       if (response && response.text) {
  //         setMessages(prevMessages => {
  //           const sender = 'ept AI';
  //           const messageIndex = prevMessages.findIndex(m => m.response_id === response_id && m.sender === sender);
  
  //           if (messageIndex !== -1) {
  //             const updatedMessages = [...prevMessages];
  //             const updatedMessage = {
  //               ...updatedMessages[messageIndex],
  //               text: updatedMessages[messageIndex].text + response.text,
  //               response_id: response_id // Use the ref's current value
  //             };
  //             updatedMessages[messageIndex] = updatedMessage;
  //             return updatedMessages;
  //           } else {
  //             return [
  //               ...prevMessages,
  //               { text: response.text, sender: sender, streamId: response.streamId, response_id: response_id, isStreamTerminated: false, output_format: output_format}
  //             ];
  //           }
  //         });
  //       }
  
  //       if (response.action === "terminate_stream") {
  //         // setResponse_id(prev => prev + 1); // Correctly increment the response_id
  //         setMessages(prevMessages =>
  //           prevMessages.map(msg =>
  //             msg.response_id === response_id ? { ...msg, isStreamTerminated: true } : msg
  //           )
  //         );
  //         setIsChatLoading(false);
  //         setIsSendButtonDisabled(false);
  //         document.querySelector('textarea').focus();
  //         console.log("Stream terminated");
  //         isWebsocketIdle = true;
  //         setTimeout(()=>{
  //             resetIdleTimer();
  //         }, 5000);
  //       }

  //       if(response.error == "Stream has been idle for too long"){
  //         // resend the last message
  //         console.log("retrying last message");
  //         setTimeout(() => {
  //           sendMessageHanlde(lastMessage);
  //         },2000);
  //       }
  
  //       if (response.isFinalPart) {
  //         setCurrentStreamId(null);
  //         isWebsocketIdle = true;
  //         setTimeout(()=>{
  //             resetIdleTimer();
  //         }, 5000);
  //       }

  //       if (response.feedback) {
  //         console.log('Feedback received:', response.feedback);
  //         setMessages((prevMessages) =>
  //             prevMessages.map((msg) =>
  //                 msg.response_id === response.feedback.response_id ? { ...msg, feedback: response.feedback } : msg
  //             )
  //         );
  //         isWebsocketIdle = true;
  //         setTimeout(() => {
  //             resetIdleTimer();
  //         }, 5000);
  //     }
        
  //     };

  //     ws.onclose = () => {
  //       console.log("WebSocket connection closed");
  //       setIsChatLoading(false); // Ensure loading state is reset on close
  //       if (pingInterval) {
  //         clearInterval(pingInterval);
  //         pingInterval = null;
  //       }
  //     };

  //     ws.onerror = (error) => {
  //       console.error("WebSocket error:", error);
  //       setIsChatLoading(false); // Ensure loading state is reset on error
  //       setConnectionError(true);
  //       setConnectionErrorMessage(error);
  //       if (pingInterval) {
  //         clearInterval(pingInterval);
  //         pingInterval = null;
  //       }
  //     };

  //     websocketRef.current = ws;
  //   } catch (error) {
  //     console.error("Error obtaining the access token or connecting:", error);
  //     setIsChatLoading(false); // Ensure loading state is reset on error
  //   }
  // };



  const sendMessageHanlde = (message) => {
    // Ensure message is a string before trying to trim it.
    // This handles cases where message might be undefined or not a string.
    const finalMessage = (typeof message === 'string' ? message.trim() : '') || inputMessage.trim();
  
    if (!finalMessage) {
      console.log("No message to send");
      return;
    }
  
    if (webSocketStatus.current === 'open') {
      setDefaultQustionBool(false);
      setIsChatLoading(true);
      setIsSendButtonDisabled(true);
  
      let dataObject = {
        message: finalMessage, // Assuming finalMessage is defined elsewhere
        action: conversation_id === '' ? 'start_conversation' : 'conversation_message',
        
        // client_key: eptClientID, // Assuming eptClientID is defined elsewhere
        // ksc_id : selectKscID,// Assuming selectKscID is defined elsewhere
      };
      
      // Only add auth0_token, user_id, and ksc_id if conversation_id is empty
      if (conversation_id === '') {
        // dataObject.auth0_token = userToken; // Assuming userToken is defined elsewhere
        // dataObject.user_id = user.sub; // Assuming user.sub is defined elsewhere
      }else {
        dataObject.conversation_id = conversation_id;
        dataObject.response_id = response_id; // Assuming response_id is defined elsewhere
      }
      dataObject.identifier = selectedChannel.channel + '-' + "chat";
      dataObject.channel_id = selectedChannel.channel;

      // console.log('dataObject:', dataObject);
  
      const data = JSON.stringify(dataObject);
      sendMessage(data); 
      setMessages(prevMessages => [...prevMessages, { text: finalMessage, sender: 'user' }]);
      // Only clear inputMessage if no valid message was passed directly or if the direct message is used
      if (typeof message !== 'string' || message.trim() === '') {
        setInputMessage('');
      }
      setTimeout(() => {
        adjustTextareaHeight(document.getElementById('chat-input-id'));
      }, 0);
      setAutoScrollEnabled(true);

    } else {
      console.error("WebSocket is not open. Message not sent.");
    }
  };

  const sendFeedback = (response_id, isPositive) => {
    if (webSocketStatus.current === 'open') {
        console.log('Sending feedback:', isPositive ? 'positive' : 'negative', 'for response ID:', response_id);
        try {
            const data = JSON.stringify({
                "action": "feedback",
                "is_positive": isPositive,
                "response_id": response_id
            });

            websocketRef.current.send(data);
        } catch (error) {
            console.error('Error sending feedback:', error);
        }
    } else {
        console.error('WebSocket is not open. Storing feedback to send later.');
        // Store feedback to resend later
        pendingFeedback.push({ response_id, isPositive });
    }
};

const resendPendingFeedback = () => {
  // Send all pending feedback
  while (pendingFeedback.length > 0) {
      const feedback = pendingFeedback.shift();
      sendFeedback(feedback.response_id, feedback.isPositive);
  }
};


  const cleanHtmlContent = (html, output_format) => {
    let cleanhtml = '';
    if(output_format === 'markdown'){
      cleanhtml = marked(html);
    }else{
      cleanhtml = html;
    }

    let sanitizedHtml = DOMPurify.sanitize(cleanhtml, { USE_PROFILES: { html: true } });
  
    // Enhanced to remove language specifier along with code block delimiters
    // This will remove instances like ```html or ```javascript along with the triple backticks
    sanitizedHtml = sanitizedHtml.replace(/```(\w+)?\s?/g, '');
  
    // Find anchor links and add target="_blank" attribute
    sanitizedHtml = sanitizedHtml.replace(/<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*?)>/gi, (match, href, rest) => {
      // Check if the link already has a target attribute
      if (!rest.includes('target=')) {
        return `<a href="${href}" target="_blank"${rest}>`;
      } else {
        return match; // Link already has a target attribute
      }
    });
  
    return sanitizedHtml;
  };


  const handleForumChange = (selectedOptions) => {
    console.log("selectedOptions:", selectedOptions);
    setSelectedChannel(selectedOptions);
    if (websocketRef.current) {
      websocketRef.current.close();
    }
    setSwitchingForum(true);

    setTimeout(() => {
      setMessages([]);
    setConversation_id('');
    setResponse_id(0);
    // setIsChatWindowLoading(true);
    // setDefaultQustionBool(true);
    // setIsSendButtonDisabled(false);
    setInputMessage('');
    }, 300);
    
    
    // stop the websocket connection
    
      // console.log("raw id: " + event.target.value);
      // Handle thread list update or any other logic here
  };

  // Helper function to adjust the textarea height
  const adjustTextareaHeight = (element) => {
      const chatBox = document.getElementById('chat-messages-wrap-id');
      const maxLines = 3;
      const lineHeight = 35; // Adjust this based on your CSS
      const baseHeight = 78; // Adjust this based on your CSS for one line height including padding

      // Directly reset the height to ensure scrollHeight reflects actual text height
      element.style.height = 'auto';

      // Force browser reflow/repaint
      element.offsetHeight; // This line is supposed to trigger reflow

      // Calculate the current number of lines, considering padding and border
      const actualHeight = Math.max(element.scrollHeight, baseHeight); // Use max to ensure at least base height is considered
      let lines = Math.ceil((actualHeight - (baseHeight - lineHeight)) / lineHeight);
      lines = Math.max(lines, 1); // Ensure there is at least one line
      lines = Math.min(lines, maxLines); // Do not exceed the maximum number of lines

      // Using a very short timeout to allow the browser to complete any pending layout calculations.
          // Remove previous height classes
          element.classList.remove('height-one-line', 'height-two-line', 'height-three-line');
          chatBox.classList.remove('height-one-line', 'height-two-line', 'height-three-line');
          element.style.height = '';

          // Add the class based on the current number of lines
          switch (lines) {
              case 1:
                  element.classList.add('height-one-line');
                  chatBox.classList.add('height-one-line');
                  break;
              case 2:
                  element.classList.add('height-two-line');
                  chatBox.classList.add('height-two-line');
                  break;
              case 3:
                  element.classList.add('height-three-line');
                  chatBox.classList.add('height-three-line');
                  break;
              default:
                  // No default action needed since we've handled all cases
                  break;
          }
  };

  const handleTextareaChange = (e) => {
    setInputMessage(e.target.value); // Update the message state
  };
  
  const handleTextareaKeyPress = (e) => {
    // If Enter is pressed without holding Shift, send the message
    if (e.key === 'Enter' && !e.shiftKey) {
      sendMessageHanlde();
      e.preventDefault(); // Prevents the default action of inserting a new line
    }
  };
  
  const animateLoadingTexts = (setCurrentText, chatLoadingText) => {
    let currentIndex = 0;
  
    const updateText = () => {
      if (currentIndex < chatLoadingText.length) {
        setCurrentText(chatLoadingText[currentIndex]);
        currentIndex++;
        if (currentIndex < chatLoadingText.length) {
          setTimeout(updateText, 1000); // Adjust time as needed
        }
      }
    };
  
    updateText(); // Start the animation
  }

  const handleDefaultQuestion = (e) => {
    document.querySelector('.default-question').style.display = 'none';
    setLastMessage(e.target.innerText);
    sendMessageHanlde(e.target.innerText);
  }    

  

  //  useEffect(() => {
  //   if (isChatWindowLoading) {
  //     return;
  //   }
  //   const chatBox = document.getElementById('chat-messages-wrap-id');
    
  //   const handleChatBoxScroll = () => {
  //   const chatBox = document.getElementById('chat-messages-wrap-id');
  //       if (!isUserScroll) {
  //           // Reset the flag to true for any future scroll events
  //           setIsUserScroll(true);
  //           return;
  //       }
  //       // Check if the user has scrolled up
  //       if (chatBox.scrollTop < chatBox.scrollHeight - chatBox.clientHeight) {
  //           // If the user has scrolled up, disable auto-scrolling
  //           setAutoScrollEnabled(false);
  //       } else {
  //           // If the user has scrolled to the bottom, enable auto-scrolling
  //           setAutoScrollEnabled(true);
  //       }
  //   }
  //   const chatInput = document.getElementById('chat-input-id');
  //   if(window.innerWidth <= 375){
  //     chatInput.addEventListener('blur', () => {
  //       setTimeout(() => {
  //         window.scrollTo(0, 0);
  //       }, 0);
  //     });

  //   }
  //       chatBox.addEventListener('scroll', handleChatBoxScroll);
  //  }, [isChatWindowLoading]);



   useEffect(() => {
    const chatBox = document.getElementById('chat-messages-wrap-id');
  if(!chatBox) return;
    if (autoScrollEnabled && !isChatWindowLoading) {
      setIsUserScroll(false);
  
      // Ensure scroll happens after the DOM has updated
      requestAnimationFrame(() => {
        chatBox.scrollTop = chatBox.scrollHeight;
      });
    }
    console.log(messages)
  }, [messages]);

  
  
  

  
  if(noAccessMesasge !== ''){
    return (
      <MainAppWrap>
        <div className="main-content response-detail-page">
          <SimpleHeader />
          <Container fluid="xxl">
            <Row>
              <Col>
                <h3 className="main-error-message">{noAccessMesasge}</h3>
              </Col>
            </Row>
          </Container>
        </div>
      </MainAppWrap>
    )
  }

  if(connectionError){
    return (
      <MainAppWrap>
        <div className="main-content response-detail-page">
          <SimpleHeader />
          <Container fluid="xxl">
            <Row>
              <Col>
                <h3 className="main-error-message">Something went wrong!</h3>
                <p className='main-error-message-p'>Please try again later. If problem presist please contact your administrator.</p>
                {connectionErrorMessage && (
                // check if connectionErrorMessage is array, string, or object, and handle accordingly
                Array.isArray(connectionErrorMessage) ? (
                  connectionErrorMessage.map((error, index) => (
                    <p key={index} className='main-error-message-p'>Error: {typeof error === 'object' ? JSON.stringify(error) : error}</p>
                  ))
                ) : (
                  <p className='main-error-message-p'>
                    Error: {typeof connectionErrorMessage === 'object' ? JSON.stringify(connectionErrorMessage) : connectionErrorMessage}
                  </p>
                )
              )}


              </Col>
            </Row>
          </Container>
        </div>
      </MainAppWrap>
    )
  } 

  return(
    isAuthenticated && (
    <MainAppWrap>
        <div className="main-content">
        <SimpleHeader />
        <div className="question-page gray_bg">
            <Container fluid className="">
                <Container fluid="xxl">
                    <Row>
                        <Col className="no-padding">
                            <QuestionHero
                                handleForumChange={handleForumChange}
                                eptChannels={eptChannels}
                                selectedChannel={selectedChannel}
                                switchingForum={switchingForum}
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid="xxl">
                <Row>
                    <Col sm={12}>
                    <div className='chat-window-wrap'>
                      {isChatWindowLoading && (
                        <LoaderWithText class='chat-loading-wrap' text={currentDisplayText} />
                      )}
                      <div className='chat-messages-wrap height-one-line' id='chat-messages-wrap-id'>
                        <ul id="eptai-chat-messages">
                          
                          <li className={`bot-message ${isChatWindowLoading ? 'hidden':'fadeIn'}`}>
                            <div className='chat-user-image'>
                              <img className='profile-avatar' src={ept_bot_img} alt='EPTAI'/>
                            </div>
                            <div className='chat-message-wrap'>
                              <div className='message-sender'>ept AI</div>
                              <span>Hi, I'm ept AI. I'm here to help you with your questions. Type a question about your products here to get an AI-generated answer based on the selected knowledge sources</span>
                            </div>
                          </li>
                          
                          {messages.map((msg, index) => (
                            <li key={msg.response_id ? `${msg.response_id}` : index} 
                                className={msg.sender === 'user' ? 'user-message' : 'bot-message'}>
                              <div className='chat-user-image'>
                                <img
                                  className='profile-avatar'
                                  src={msg.sender === 'user' ? user.picture : ept_bot_img}
                                  alt={msg.sender === 'user' ? user.name : 'EPTAI'}
                                />
                              </div>
                              <div className='chat-message-wrap'>
                                <div className='message-sender'>{msg.sender === 'ept AI' ? 'ept AI' : 'You'}</div>
                                <div id={msg.response_id} dangerouslySetInnerHTML={{ __html: msg.sender === 'user' ? msg.text.replace(/\n/g, '<br>') : cleanHtmlContent(msg.text, msg.output_format) }}></div>
                                <div>{msg.isStreamTerminted}</div>
                                {msg.sender === 'ept AI' && msg.isStreamTerminated && (
                                  <FeedbackAndCopy
                                    response_id={msg.response_id}
                                    sendFeedback={sendFeedback}
                                    feedback={msg.feedback}
                                    detailLink={true}
                                  />
                                )}
                              </div>
                              
                            </li>
                          ))}
                        </ul>
                        {defaultQustionBool && (
                        <div className={`default-question ${isChatWindowLoading ? 'hidden' : 'fadeIn'}`}>
                          <li onClick={(e)=> handleDefaultQuestion(e)}>What are your products?</li>
                        </div>
                        )}
                        {isChatLoading && (
                          <Loader />
                        )}
                        {reconnectionLoading && (
                          <LoaderWithText class='reconnection-loading-wrap' text='Reconnecting, please wait...' />
                        )}
                      </div>
                      <div className={`chat-input-button-wrap ${isChatWindowLoading ? 'hidden' : 'fadeIn'}`}>                        
                        <textarea
                          value={inputMessage}
                          onChange={handleTextareaChange}
                          onKeyPress={handleTextareaKeyPress}
                          onInput={(e) => adjustTextareaHeight(e.target)}
                          placeholder="Type a message..."
                          disabled={isChatLoading}
                          className='height-one-line'
                          id='chat-input-id'
                        ></textarea>
                        <button className='ask-send-btn' onClick={sendMessageHanlde} disabled={isSendButtonDisabled || isChatLoading}></button>
                      </div>
                      
                    </div>
                    </Col>
                </Row>
            </Container>
        </div>
        <Footer />
        </div>
    </MainAppWrap>
    )
);
};

export default AskQuestionEptChat;
