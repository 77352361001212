import React, {useState, useEffect} from "react";

import { Container, Row, Col } from "react-bootstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill's CSS
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useAuth0 } from "@auth0/auth0-react";
import { apiPath } from "../../../config";
import {marked}  from "marked";
import DOMPurify from 'dompurify';
import { Link, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import {useWebSocket} from "../../common/WebsocketContext";

import SettingsLoader from "../../common/SettingsLoader";
import LoaderWithText from "../../common/LoaderWithText";
import TurndownService from "turndown";
import axios from "axios";
import { is } from "date-fns/locale";

function GkdEditorComp(props) {
  const navigate = useNavigate();
    const {
      gkdData,
      setGkdData,
      ks_id,
      filename,
      isLoadingPage,
      brainDump,
      gkdURL,
      isStreamingLoading,
      isBrainDump,
      setShowRecordingButton,
      hideActionButtons,
      showRecordingButton,
      showGKD,
      setShowGKD,
    } = props;
    const { user, isAuthenticated, getIdTokenClaims } = useAuth0();

    const turndownService = new TurndownService();

  const { sendMessage, addListener, removeListener } = useWebSocket();
  const [currentMessage, setCurrentMessage] = useState('');
  const [uploadProgress, setUploadProgress] = useState({});



    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    const [content_html, setContentHtml] = useState("");
    const [listOfQuestions, setListOfQuestions] = useState([]);
    const [references, setReferences] = useState([]);
  
  
    const [tempTitle, setTempTitle] = useState("");
    const [tempContent, setTempContent] = useState("");
    const [tempListOfQuestions, setTempListOfQuestions] = useState([]);
    const [tempListOfReferences, setTempListOfReferences] = useState([]);
  
  
  
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingContent, setIsEditingContent] = useState(false);
  
  
    const [editQuestion, setEditQuestion] = useState("");
    const [editQuestionIndex, setEditQuestionIndex] = useState(null);
  
  
    const [editReferenceIndex, setEditReferenceIndex] = useState(null);
    const [editTitle, setEditTitle] = useState("");
    const [editUrl, setEditUrl] = useState("");
  
    
  
    const [generateQuestionLoading, setGenerateQuestionLoading] = useState(false);

    const [showBraindumpButton, setShowBraindumpButton] = useState(true);

    
  
  
    // Specify the formats you want ReactQuill to handle
    const formats = [
      'header', 'bold', 'italic', 'underline', 'strike', 'blockquote',
      'list', 'bullet', 'indent', 'link', 'image', 'video'
    ];
  
    // Optionally, define any custom modules to enhance the editing experience
    const modules = {
      toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
        ['link', 'image', 'video'],
        ['clean']
      ]
    };

    useEffect(() => {
      if(gkdData){
        gkdData?.title && setTitle(gkdData.title);
        gkdData?.text && setContent(cleanMarkdown(gkdData.text));
        gkdData?.questions && setListOfQuestions(gkdData.questions);
        gkdData?.references && setReferences(gkdData.references);
        console.log("gkdData: ", gkdData);
      }
    }, [gkdData])

    const getUploadURL = async (files) => {
      try {
        const filesArray = files.map((file) => ({
          filename: file.path,
          content_type: file.type
        }));
        const getidToken = await getIdTokenClaims();
        const idToken = getidToken.__raw;
        const formattedData = JSON.stringify({ items: filesArray });
        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${idToken}`,
            "Content-Type": "application/json",
          },
          body: formattedData,
        };
        const ks_id = props.ks_id; // Replace with actual ks_id
        const path = `/ks_items?ks_id=${ks_id}`;
        const url = `${apiPath()}${path}`;
        const response = await fetch(url, options);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const result = await response.json();
        return result; // Return the array of file objects with URLs
      } catch (error) {
        console.error("Error fetching upload URLs:", error);
        throw error;
      }
    };
    
const saveUpdates = async () =>{

    if (brainDump) {

      let customURL = "";

      if(title === ""){
        toast.error("Filename is required for GKD",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
        );
        return;
      }

      if(content_html.length < 100){
        toast.error("Content is too short. Please add more content.",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
        );
        return;
      }

      if(gkdURL === ""){
        toast.info("Generating file infor...",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
        );
        customURL = await getUploadURL([{ path: `${title.replace(/<\/?[^>]+(>|$)/g, "").trim().replace(/\s+/g, "_")}.gkd`, type: "application/json" }]);
        console.log("customURL: ", customURL);
      }

      // Convert HTML to Markdown
      const content_markdown = turndownService.turndown(content_html);
      const trimTitle = title.replace(/<[^>]*>?/gm, '');
      // Create the data object that needs to be saved in the .gkd file
      let data = {
        title: trimTitle,
        text: content_markdown,
        questions: listOfQuestions,
        references: references
      };
      console.log("new: ",data);
      

      // Merge with any existing gkdData (if needed)
      // let updatedData = { ...gkdData, ...data };

      // Convert the data object to a JSON string
      const jsonContent = JSON.stringify(data, null, 2); // Pretty print with 2 spaces

      // Create a blob containing the JSON string, keeping the type as JSON but using the .gkd extension
      const blob = new Blob([jsonContent], { type: 'application/json' });

      // Upload the file with the .gkd extension
      await uploadFileToURL(blob, gkdURL === "" ? customURL[0].url : gkdURL);

      console.log("File uploaded successfully");
      return;
    }

    const toastid = toast.loading("Please wait updating GKD...", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    try {
      
      const content_markdown = turndownService.turndown(content_html);
      const trimTitle = title.replace(/<[^>]*>?/gm, '');
      let data = {
        title: trimTitle,
        text: content_markdown,
        questions: listOfQuestions,
        references: references
      };
      let updatedData = { ...gkdData, ...data };
      console.log("updated: ",updatedData);
      
      
      const idToken = await getIdTokenClaims();
  
      // Log the token for debugging
      console.log("ID Token:", idToken.__raw);
  
      // Check if the token is correctly formatted
      if (!idToken || !idToken.__raw || typeof idToken.__raw !== "string") {
        throw new Error("Invalid ID Token: Token is missing or incorrectly formatted.");
      }
      let path = `${apiPath()}/update_gkd/${ks_id}/${filename}`;
        const response = await fetch(path, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${idToken.__raw}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(updatedData)
        });
        if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
        const responseData = await response.json();
        console.log(responseData);
  
      if(responseData){
        if(brainDump){
          toast.update(toastid, {
            render: "GKD created successfully",
            type: toast.TYPE.SUCCESS,
            isLoading: false,
            autoClose: 2000,
          }); 
        }else {
          toast.update(toastid, {
            render: "GKD updated successfully",
            type: toast.TYPE.SUCCESS,
            isLoading: false,
            autoClose: 2000,
          });
        }
      }
    } catch (error) {
      console.error("Error saving updates:", error);
      toast.update(toastid, {
        render: "Error updating GKD",
        type: toast.TYPE.ERROR,
        isLoading: false,
        autoClose: 2000,
      });
    }
  }  
  const uploadFileToURL = async (file, uploadURL) => {
    const toastid = toast.loading("Uploading file...", {
      position: toast.POSITION.BOTTOM_RIGHT,
    });
    try {
      const response = await axios.put(uploadURL, file, {
        headers: {
          "Content-Type": file.type
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(prevProgress => ({
            ...prevProgress,
            [file.name]: percentCompleted
          }));
        }
      });
      if (response.status !== 200) {
        throw new Error(`Upload failed! Status: ${response.status}`);
      }
      toast.update(toastid, {
        render: "GKD created successfully",
        type: "success",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      setTimeout(() => {
        navigate(`/settings/ks-details/${ks_id}`);
      }, 3000);

    } catch (error) {
      toast.update(toastid, {
        render: "Error creating GKD",
        type: "error",
        isLoading: false,
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 3000,
      });
      console.error("Error uploading file to URL:", error);
      throw error;
    }
  };
  
    const update = (type) => {
      setIsEditingTitle(false);
      if(type === "content"){
        if(content_html.length < 100){
          toast.error("Content is too short. Please add more content.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
          );
          return;
        }
        setIsEditingContent(false);
  
      }
    }
  
    const showContentCount = () => {
      return content_html.length;
    }
  
    const handleEditing = (type) => {
      switch (type) {
        case "title":
          setIsEditingTitle(true);
          setTempTitle(title);
          break;
        case "content":
          setIsEditingContent(true);
          setTempContent(content_html);
          break;
        default:
          break;
      }
    };
  
    const cancelEditing = (type) => {
      switch (type) {
        case "title":
          setIsEditingTitle(false);
          setTitle(tempTitle);
          break;
        case "content":
          setIsEditingContent(false);
          setContent(tempContent);
          setContentHtml(tempContent);
          break;
        default:
          break;
      }
    }
  // Drag and drop handler for questions
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(listOfQuestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setListOfQuestions(items);
  };
  
  // ------------------- Question Handling -------------------
  const addNewQuestion = () => {
    setListOfQuestions([...listOfQuestions, ""]); // Add empty string as a new question
    setEditQuestionIndex(listOfQuestions.length);
    setEditQuestion("");
  
  }
  
  const saveQuestion = (index) => {
    if (editQuestion.trim() === "") {
      removeQuestion(index);
    } else {
      const updatedQuestions = [...listOfQuestions];
      updatedQuestions[index] = editQuestion;
      setListOfQuestions(updatedQuestions);
      setEditQuestionIndex(null);
      setEditQuestion("");
    }
  };
  
  const removeQuestion = (index) => {
    const updatedQuestions = listOfQuestions.filter((_, i) => i !== index);
    setListOfQuestions(updatedQuestions);
    setEditQuestionIndex(null);
    setEditQuestion("");
  };
  const cancelQuestion = (index) => {
    setEditQuestionIndex(null);
    setEditQuestion("");
  };
  
  const startEditingQuestion = (index) => {
    setEditQuestionIndex(index);
    setEditQuestion(listOfQuestions[index]);
  };
  
  // ------------------- Reference Handling -------------------
  const addNewReference = () => {
    
    setReferences([...references, { title: "", url: "" }]); // Add empty reference
    setEditReferenceIndex(references.length);
    setEditTitle("");
    setEditUrl("");
  }
  
  const saveReference = (index) => {
    if (editTitle.trim() === "" || editUrl.trim() === "") {
      removeReference(index);
    } else {
      const updatedReferences = [...references];
      updatedReferences[index] = { title: editTitle, url: editUrl };
      setReferences(updatedReferences);
      setEditReferenceIndex(null);
      setEditTitle("");
      setEditUrl("");
    }
  };
  
  const removeReference = (index) => {
    const updatedReferences = references.filter((_, i) => i !== index);
    setReferences(updatedReferences);
    setEditReferenceIndex(null);
    setEditTitle("");
    setEditUrl("");
  };
  const cancelReference = (index) => {
    setEditReferenceIndex(null);
    setEditTitle("");
    setEditUrl("");
  };
  
  const startEditingReference = (index) => {
    setEditReferenceIndex(index);
    setEditTitle(references[index].title);
    setEditUrl(references[index].url);
  };
  
  
    useEffect(() => {
      if(content){
        setContentHtml(marked(content));
      }
    }, [content]);
  
    useEffect(() => {
      // Listener function to handle incoming WebSocket messages
      const handleMessage = (message) => {
        
        // setCurrentMessage(message);
        let data = JSON.parse(message);
        if(data.action === 'generate_gkd_questions' && data.identifier === ks_id + '-' + filename){
          // data.gkd_questions make it proper array and set it to listOfQuestions
          
          if (data && data.gkd_questions) {
            console.log("generated: ",data.gkd_questions);
            setListOfQuestions(data.gkd_questions);
            setGenerateQuestionLoading(false);
          }
        }
      };
  
      // Subscribe to WebSocket messages
      addListener(handleMessage);
  
      // Cleanup the listener on component unmount
      return () => {
        removeListener(handleMessage);
      };
    }, [addListener, removeListener]);
  
    const handleSendMessage = () => {
      const content_markdown = turndownService.turndown(content_html);
      if(content_markdown === ""){
        toast.error("Content is empty. Please add content to generate questions.",
        {
          position: toast.POSITION.BOTTOM_RIGHT,
        }
        );
        return;
      }
      console.log(content_markdown)
      setGenerateQuestionLoading(true);
      const message = {'text': content_markdown, 'action': 'generate_gkd_questions', 'identifier': ks_id + '-' + filename};
  
      sendMessage(JSON.stringify(message));
    };

    const cleanMarkdown = (markdown) => {
  // Remove markdown from the start of the string
    const strippedStart = markdown.replace(/^[#>*`~\-\*\_]+[\s]?/gm, '');

    // Remove markdown code blocks at the end
    const strippedEnd = strippedStart.replace(/(\n|^)\`\`\`.*?(\n|$)/gs, '');

    return strippedEnd.trim();
  };
    
  return (
    <div>
          <div className="gkd-editor-page">
            {/* <Row>
              <Col>
                <h1>Editor</h1>
              </Col>
            </Row> */}
            {isStreamingLoading && (
              <Row>
                <Col>
                <div style={{marginTop: "20px"}}>
                  <LoaderWithText text="Processing voice input to generate GKD!" />
                </div>
                </Col>
              </Row>
            )}
            {isBrainDump !== undefined && showBraindumpButton &&  (
              <Row>
            <Col className="braindump-btn-wrap">
              <a href="#" onClick={(e) => {
                e.preventDefault();
                setShowGKD(false);
                setShowRecordingButton(true);
                setShowBraindumpButton(false);
              }}
              className="braindump-btn">
              Brain Dump
              </a>
            </Col>
            </Row>
            )}
            {showGKD && (
            <>
            <Row>
            
            <Col className={`editable-content`}>
            
            <p className="gkd-filename" onClick={() => handleEditing("title")}>Filename: {title && !isEditingTitle && <span dangerouslySetInnerHTML={{__html: title.replace(/<[^>]*>?/gm, '')}}></span>}
              {!isEditingTitle && title === "" ?  <span className="gkd-placeholder"> Please enter the file name here!</span> : null}
            </p>
            {!isEditingTitle && (
              <a className="gkd-edit-btn gkd-edit-btn-hover" href="#" onClick={() => handleEditing("title")}>
              </a>
            )}
            {isEditingTitle && (
              <div>
              <div>
                <ReactQuill
                  value={title}
                  onChange={setTitle} 
                  theme="snow" // You can choose a different theme if needed
                  modules={{
                    toolbar: false, // Disable the toolbar for single-line editing
                  }}
                  formats={['header']}
                  
                />
              </div>
              <div className="gkd-action">
              <button className="gkd-cross" onClick={() => cancelEditing("title")}></button>
                <button className="gkd-check" onClick={() => update("title")}></button>
              </div>
              </div>
            )}
          </Col>

          </Row>
          <Row>
            <Col className={`editable-content`}>
              <h3>Content</h3>
              {content_html && !isEditingContent && (
                <div onClick={() => handleEditing("content")} className="gkd-content gkd-curser" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(content_html)}}></div>
              )
              }
              {content_html === "" && !isEditingContent && <p onClick={() => handleEditing("content")} className="gkd-placeholder">Please enter the content here!</p>}
              {!isEditingContent && (
                <a className="gkd-edit-btn gkd-edit-btn-hover" href="#" onClick={() => handleEditing("content")}>
                </a>
              )}
              {isEditingContent && (
                <div>
                <div>
                <ReactQuill
                    value={content_html}
                    onChange={setContentHtml}
                    formats={formats}
                    modules={modules}
                  />
                </div>
                <div className="gkd-action">
                {showContentCount() < 100 && <p className="gkd-content-count red">Content is too short</p>}
                <button className="gkd-cross" onClick={() => cancelEditing("content")}></button>
                <button className="gkd-check" onClick={() => update("content")}></button>
                
                </div>
                </div>
              )}
            </Col>
          </Row>
          {/* Questions Section */}
      <Row>
        <Col className={`editable-content no-hover`}>
          <h3>Questions answered by this document</h3>
          {listOfQuestions && listOfQuestions.length === 0 && generateQuestionLoading == false && isBrainDump === undefined && (
            <>
              <p style={{ color: "#666", fontStyle: "italic" }}>No questions added yet</p>
              <button className="gkd-generate-btn" onClick={handleSendMessage}>Generate</button>
            </>
          )}
          {generateQuestionLoading && (
            <LoaderWithText text="Generating questions..." />
          )}
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="questions">
              {(provided) => (
                <ul className="gkd-list" {...provided.droppableProps} ref={provided.innerRef} style={{ padding: 0, listStyleType: "none" }}>
                  {listOfQuestions?.map((item, index) => (
                    <Draggable key={index} draggableId={`${index}`} index={index}>
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={{
                            
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            ...provided.draggableProps.style,
                          }}
                        >
                        <span className="drag-icon"></span>
                          {editQuestionIndex === index ? (
                            <>
                              <input
                                type="text"
                                placeholder="Type your question..."
                                value={editQuestion}
                                onChange={(e) => setEditQuestion(e.target.value)}
                                style={{ flex: 1, marginRight: "10px" }}
                              />
                              <button className="gkd-cross" onClick={() => cancelQuestion(index)}></button>
                              <button className="gkd-check" onClick={() => saveQuestion(index)}></button>
                            </>
                          ) : (
                            <>
                              <span style={{ flex: 1 }}>{item}</span>
                              <div className="gkd-main-action-btn">
                                <button className="gkd-edit-item" onClick={() => startEditingQuestion(index)} style={{ marginRight: "5px" }}>
                                </button>
                                <button className="gkd-delete-item" onClick={() => removeQuestion(index)}></button>
                              </div>
                            </>
                          )}
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
          <button className="gkd-add-btn" onClick={addNewQuestion}>
          <span></span>Add
          </button>
        </Col>
      </Row>

      {/* References Section */}
      <Row>
        <Col className={`editable-content no-hover`}>
          <h3>References</h3>
          <div>
          <ul className="gkd-list">
            {references.map((ref, index) => (
              <li
                key={index}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {editReferenceIndex === index ? (
                  <>
                    <input
                      value={editTitle}
                      placeholder="Title"
                      onChange={(e) => setEditTitle(e.target.value)}
                      style={{ marginRight: "10px", flex: 1 }}
                    />
                    <input
                      value={editUrl}
                      placeholder="URL"
                      onChange={(e) => setEditUrl(e.target.value)}
                      style={{ marginRight: "10px", flex: 2 }}
                    />
                    <button className="gkd-cross" onClick={() => cancelReference(index)}></button>
                    <button className="gkd-check" onClick={() => saveReference(index)}></button>
                  </>
                ) : (
                  <>
                    <span style={{ flex: 1 }}>{ref.title}</span>
                    <span style={{ flex: 2 }}>{ref.url}</span>
                    <div className="gkd-main-action-btn">
                      <button className="gkd-edit-item" onClick={() => startEditingReference(index)} style={{ marginRight: "5px" }}>
                      </button>
                      <button className="gkd-delete-item" onClick={() => removeReference(index)}></button>
                    </div>
                  </>
                )}
              </li>
            ))}
            </ul>
          </div>
          <button className="gkd-add-btn" onClick={addNewReference}>
            <span></span>Add
          </button>
        </Col>
      </Row>
      
      <Row>
        <Col>
        <div className="gkd-final-buttons">
          <Link to={`/settings/ks-details/${ks_id}`} className="gkd-cancel-btn">
            Cancel
          </Link>
          <button className="gkd-save-btn" onClick={saveUpdates}>
            Save
          </button>
          </div>
        </Col>
      </Row>
      </>
    )}
    
          </div>
    </div>
  );
}

export default GkdEditorComp;