import React from "react";
import DirectChat from "./DirectChat";

const ChatBox = ({ element_id, question, top, left, handleChatClose }) => {
  if (!element_id || !question) return null;

  return (
    <div className="chat-component">
      <DirectChat
        key={element_id} // Using 'key' to force re-mount
        element_id={element_id}
        question={question}
        overlay={true}
        top={top}
        left={left}
        handleChatClose={handleChatClose}
      />
    </div>
  );
};

export default ChatBox;
