import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { Navbar, Container, NavDropdown, DropdownButton } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import mobileLogo from '../images/mobile_logo.svg';
import { useForumList } from './common/ForumListContext';
import { clearLocalStorageItems } from './common/Functions';
import Chatbox from './chat/ChatBox';
import  useChatbox  from './chat/UseChatBox';

function SimpleHeader(props) {
  const { forumIds, selectedForumId } = useForumList();
  const { question, element_id, top, left, handleChat, handleChatClose } = useChatbox();
  const { user, logout } = useAuth0();
  const [groups, setGroups] = useState([]);
  const [formattedDate, setFormattedDate] = useState("");
  const [timeOfDay, setTimeOfDay] = useState("");
  const navigate = useNavigate();

  const location = useLocation();
  const [dropdownTitle, setDropdownTitle] = useState('Dropdown');

  

  useEffect(() => {
    // Update the Dropdown title based on the current location or selection
    if (location.pathname === '/') {
      setDropdownTitle('Ask a question');
    } else if (location.pathname === '/community-forum') {
      setDropdownTitle('Community Forum');
    } else {
      // Set to default or adjust based on other paths/selections
      const activeForum = forumIds.find(forum => forum.value === selectedForumId);
      if (activeForum) {
        setDropdownTitle(activeForum.label.replace(/(http|https|s:|\/)/g, ''));
      }
    }
  }, [location, selectedForumId, forumIds]); 

  useEffect(() => {
    if (user !== null) {
      // console.log('Current Auth user:', user);
      setGroups(user?.ept_roles);
      // console.log('Current Auth user groups:', user?.ept_roles);
      if(props.setGroups !== undefined ) {
        props.setGroups(user?.ept_roles);
      }
      // Perform additional logic or actions with the user info
    }
  }, [user]);

  useEffect(() => {
    const date = new Date();
    const options = { weekday: 'long', month: 'long', day: 'numeric' };
    const day = date.getDate();
    const month = date.toLocaleDateString(undefined, { month: 'long' });

    // Function to add 'st', 'nd', 'rd', or 'th' to the day
    const getDayWithOrdinal = (day) => {
      if (day >= 11 && day <= 13) {
        return `${day}th`;
      }
      switch (day % 10) {
        case 1:
          return `${day}st`;
        case 2:
          return `${day}nd`;
        case 3:
          return `${day}rd`;
        default:
          return `${day}th`;
      }
    };

    const formattedDayName = date.toLocaleDateString(undefined, { weekday: 'long' });
    const formattedDay = getDayWithOrdinal(day);
    const formattedYear = date.getFullYear();

    const hours = date.getHours();

    // Determine time of day based on hours
    let timeOfDayText = '';
    if (hours >= 5 && hours < 12) {
      timeOfDayText = 'Morning';
    } else if (hours >= 12 && hours < 17) {
      timeOfDayText = 'Afternoon';
    } else if (hours >= 17 && hours < 21) {
      timeOfDayText = 'Evening';
    } else {
      timeOfDayText = 'Night';
    }

    setTimeOfDay(timeOfDayText);

    const formattedDateStr = `${formattedDayName}, ${month} ${formattedDay}, ${formattedYear}`;
    setFormattedDate(formattedDateStr);
  }, []);

  return (
    <div>
      <Navbar className='ept-header'>
        <Container fluid="xxl">
          <Navbar.Brand className='newtimeandwave'>
          {props.title &&  (
            <span>{props.title}</span>
            )}
          </Navbar.Brand>
          <Navbar.Brand className='mobile-logo'>
            <img src={mobileLogo} alt="Mobile Logo" />
          </Navbar.Brand>
          <Navbar.Toggle />
          <div className='mobile-menu'>
            <DropdownButton title={dropdownTitle} id="mobile-menu">
              <Link to="/" className={`ask-btn ${location.pathname === '/' ? 'active' : ''}`}>
                  <span>Ask a question</span>
              </Link>
              <Link to="/community-forum" className={`community-btn ${location.pathname === '/community-forum' ? 'active' : ''}`}>
                    <span>Community Forum</span>
              </Link>
            </DropdownButton>
          </div>
          <Navbar.Collapse className="justify-content-end">
            <NavDropdown title="" id="basic-nav-dropdown" className="menu-dropdown help-dropdown">
              <NavDropdown.Item href="#" onClick={handleChat} data-id="support_question">Chat</NavDropdown.Item>
              <NavDropdown.Item href="https://support.ept.ai/hc/en-us/requests/new" target="_blank">Contact Support</NavDropdown.Item>
              <NavDropdown.Item href="https://support.ept.ai/hc/en-us" target="_blank">Knowledge Base</NavDropdown.Item>
            </NavDropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Chatbox
        element_id={element_id}
        question={question}
        top={top}
        left={left}
        handleChatClose={handleChatClose}
       />
    </div>
  );
}

export default SimpleHeader;
