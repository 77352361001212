import React, { useState, useEffect } from "react";

import { Container, Row, Col, Form } from "react-bootstrap";

import SettingsLoader from "../../common/SettingsLoader";

function KsDetailsHeader(props) {
  const {
    ksDetails,
    isLoadingKS,
    handleInlineEdit,
    handleDeleteWarning,
    handleVersions,
    activeVersion,
    convertDateIntoLocalDate,
    newName,
    setNewName,
    editingName,
    setEditingName,
    newURL,
    setNewURL,
    editingURL,
    setEditingURL,
    newConfidential,
    setNewConfidential,
    newDescription,
    setNewDescription,
    editingDescription,
    setEditingDescription,
    newGithubOrg,
    setNewGithubOrg,
    editingGithubOrg,
    setEditingGithubOrg,
    handleFileUploadPopup,
  } = props;

  return (
    <Container fluid="xxl" className="">
      <Row>
        <Col>
          {isLoadingKS && <SettingsLoader />}
          {!isLoadingKS && (
            <div className="response-details-header ks-details-header">
              <div className="row">
                <Col className="ks-details-id" xs={3}>
                  <div className="editable-li">
                    <p>KS Name:</p>
                    <div className="inline-edit-wrap">
                      {editingName ? (
                        <Form.Control
                          type="text"
                          value={newName}
                          onChange={(e) => setNewName(e.target.value)}
                          className="inline-edit-input"
                          autoFocus
                          placeholder="Enter new name"
                        />
                      ) : (
                        <h3>{newName || "-"}</h3>
                      )}
                      {!editingName && ksDetails.can_edit && (
                        <div
                          className="inline-edit-btn"
                          onClick={() => setEditingName(true)}
                        ></div>
                      )}

                      {editingName && (
                        <div className="inline-edit-btns">
                          <button
                            className="btn btn-primary update-inline-edit"
                            onClick={() => {
                              // Call API to update name
                              handleInlineEdit("name", newName);
                              setEditingName(false);
                            }}
                          ></button>
                          <button
                            className="btn btn-secondary cancel-inline-edit"
                            onClick={() => {
                              setEditingName(false);
                              setNewName(ksDetails.name);
                            }}
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="response-id">
                    <p>KS ID</p>
                    <h3>{ksDetails.ks_id || "-"}</h3>
                  </div>
                </Col>
                <Col className="ksdetail-type-status" xs={3}>
                  <div className="ksdetail-type">
                    <p>KS Type</p>
                    <h3>{ksDetails.type || "-"}</h3>
                  </div>

                  <div className="ksdetail-status-item">
                    <p>Status</p>
                    {ksDetails.status === "active" && (
                      <span className="details-status">Active</span>
                    )}
                    {ksDetails.status === "pending" && (
                      <span className="details-status-pending">Pending</span>
                    )}
                  </div>
                </Col>
                <Col className="ksdetail-status" xs={3}>
                  {ksDetails.url && (
                    <div className="editable-li">
                      <p>URL</p>
                      <div className="inline-edit-wrap">
                        {editingURL ? (
                          <Form.Control
                            type="text"
                            value={newURL}
                            onChange={(e) => setNewURL(e.target.value)}
                            className="inline-edit-input"
                            autoFocus
                            placeholder="Enter new URL"
                          />
                        ) : (
                          <h3>{newURL || "-"}</h3>
                        )}
                        {!editingURL &&
                          ksDetails.can_edit &&
                          ksDetails.type !== "community_forum" &&
                          ksDetails.type !== "github" && (
                            <div
                              className="inline-edit-btn"
                              onClick={() => setEditingURL(true)}
                            ></div>
                          )}

                        {editingURL && (
                          <div className="inline-edit-btns">
                            <button
                              className="btn btn-primary update-inline-edit"
                              onClick={() => {
                                // Call API to update URL
                                handleInlineEdit("url", newURL);
                                setEditingURL(false);
                              }}
                            ></button>
                            <button
                              className="btn btn-secondary cancel-inline-edit"
                              onClick={() => {
                                setEditingURL(false);
                                setNewURL(ksDetails.url);
                              }}
                            >
                              X
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  {newGithubOrg && (
                    <div className="editable-li">
                      <p>GitHub Org</p>
                      <div className="inline-edit-wrap">
                        {editingGithubOrg ? (
                          <Form.Control
                            type="text"
                            value={newGithubOrg}
                            onChange={(e) => setNewGithubOrg(e.target.value)}
                            className="inline-edit-input"
                            autoFocus
                            placeholder="Enter new GitHub Org"
                          />
                        ) : (
                          <h3>{newGithubOrg || "-"}</h3>
                        )}
                        {!editingGithubOrg &&
                          ksDetails.can_edit &&
                          ksDetails.type === "github" && (
                            <div
                              className="inline-edit-btn"
                              onClick={() => setEditingGithubOrg(true)}
                            ></div>
                          )}

                        {editingGithubOrg && (
                          <div className="inline-edit-btns">
                            <button
                              className="btn btn-primary update-inline-edit"
                              onClick={() => {
                                // Call API to update URL
                                handleInlineEdit("github_org", newGithubOrg);
                                setEditingGithubOrg(false);
                              }}
                            ></button>
                            <button
                              className="btn btn-secondary cancel-inline-edit"
                              onClick={() => {
                                setEditingGithubOrg(false);
                                setNewGithubOrg(ksDetails.gihub_org);
                              }}
                            >
                              X
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="editable-li">
                    <p>Confidential</p>
                    <p className="confidential-switch">
                      <input
                        type="checkbox"
                        id="confidentialSwitch"
                        className="form-check-input"
                        checked={newConfidential}
                        onChange={(e) => {
                          if (ksDetails.can_edit) {
                            setNewConfidential(e.target.checked);
                            handleInlineEdit("confidential", e.target.checked);
                          }
                        }}
                      />
                      <label
                        htmlFor="confidentialSwitch"
                        className="toggle-label"
                      ></label>
                    </p>
                  </div>
                </Col>
                <Col className="ksdetail-description" xs={3}>
                  <div className="editable-li">
                    <p>Description</p>
                    <div className="inline-edit-wrap">
                      {editingDescription ? (
                        <Form.Control
                          type="text"
                          value={newDescription}
                          onChange={(e) => setNewDescription(e.target.value)}
                          className="inline-edit-input"
                          autoFocus
                          placeholder="Enter new description"
                        />
                      ) : (
                        <h3>{newDescription || "-"}</h3>
                      )}
                      {!editingDescription && ksDetails.can_edit && (
                        <div
                          className="inline-edit-btn"
                          onClick={() => setEditingDescription(true)}
                        ></div>
                      )}
                      {editingDescription && (
                        <div className="inline-edit-btns">
                          <button
                            className="btn btn-primary update-inline-edit"
                            onClick={() => {
                              // Call API to update description
                              handleInlineEdit("description", newDescription);
                              setEditingDescription(false);
                            }}
                          ></button>
                          <button
                            className="btn btn-secondary cancel-inline-edit"
                            onClick={() => {
                              setEditingDescription(false);
                              setNewDescription(ksDetails.description);
                            }}
                          >
                            X
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  {ksDetails.type !== "contributed_knowledge" && (
                    <div>
                      <p>Ingested at</p>
                      <h3>
                        {(activeVersion &&
                          convertDateIntoLocalDate(
                            activeVersion?.ingest_date
                          )) ||
                          "-"}
                      </h3>
                    </div>
                  )}
                </Col>

                <div className="ksdetail-actions">
                {ksDetails.type === "contributed_knowledge" && (
                  <a
                    href="#"
                    className="show-upload-btn"
                    onClick={(e) => handleFileUploadPopup(e)}
                  ></a>
                )}
                  {ksDetails.can_edit && (
                  <a
                    href="#"
                    className="delete-ks-btn"
                    onClick={(e) => handleDeleteWarning(e)}
                  ></a>
                    )}
                </div>
                {/* <ul>

                  
                  {ksDetails.can_edit && (
                    <li>
                      <h3>Delete</h3>
                      <p>
                        <a
                          href="#"
                          className="delete-ks"
                          onClick={(e) => handleDeleteWarning(e)}
                        ></a>
                      </p>
                    </li>
                  )}
                  {ksDetails.type !== "contributed_knowledge" && (
                    <li>
                      <a
                        href="#"
                        className="ingest-ks"
                        onClick={(e) => {
                          handleVersions(e, "ingest");
                        }}
                      >
                        Ingest
                      </a>
                    </li>
                  )}
                </ul> */}
              </div>
            </div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default KsDetailsHeader;
