import React, {useState, useEffect} from "react";

import { Container, Row, Col } from "react-bootstrap";
import MainAppWrap from "../../../layout/MainAppWrap"
import SimpleHeader from "../../SimpleHeader";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { apiPath } from "../../../config";
import { toast, ToastContainer } from "react-toastify";
import SettingsLoader from "../../common/SettingsLoader";
import GkdEditorComp from "./GkdEditorComp.js";


function GkdEditor() {
  
  const { user, isAuthenticated, getIdTokenClaims } = useAuth0();

  const {ks_id, filename} = useParams();

  const [gkdData, setGkdData] = useState({});

  const [isLoadingPage, setIsLoadingPage] = useState(true);
  
  

  const getGKD = async () =>{
    // fetch gkd data from server
    // set title, summery, content, listOfQuestions, references
    try {
      setIsLoadingPage(true);
      const idToken = await getIdTokenClaims();
      const response = await fetch(`${apiPath()}/get_gkd/${ks_id}/${filename}`, {
          method: "GET",
          headers: {
              Authorization: `Bearer ${idToken.__raw}`,
              "Content-Type": "application/json",
          },
      });
      if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);
      const data = await response.json();
      setGkdData(data);
      console.log(data);
      
      setIsLoadingPage(false);
      
  } catch (error) {
      console.error("Error fetching gkd:", error);
      setIsLoadingPage(false);
  } finally {
    setIsLoadingPage(false);
  }

  };

  useEffect(() => {
    if(!user){
      return;
    }
    // updateGKD()
    getGKD();
  }, [user]);

  

  if(isLoadingPage){
    return (
      <MainAppWrap>
        <div className="main-content gkd-bg">
          <SimpleHeader title="Guided Knowledge Document" />
          <Container fluid="xxl">
            <div className="gkd-editor-page">
              <Row>
                <Col>
                  <SettingsLoader />
                </Col>
              </Row>
            </div>
          </Container>
        </div>
      </MainAppWrap>
    );
  }

  return (
    <MainAppWrap>
      <div className="main-content gkd-bg">
        <SimpleHeader title="Guided Knowledge Document" />
        <Container fluid="xxl">
          <GkdEditorComp 
          gkdData={gkdData}
          setGkdData={setGkdData}
          ks_id={ks_id}
          filename={filename}
          isLoadingPage={isLoadingPage}
          hideActionButtons={false}
          showGKD={true}
          />
        </Container>
      </div>
      <ToastContainer />
    </MainAppWrap>
    );
}

export default GkdEditor;