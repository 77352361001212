import { useState } from "react";
import chat_content from "../../chat_content.json";

const UseChatBox = () => {
  const [question, setQuestion] = useState("");
  const [element_id, setElementId] = useState("");
  const [top, setTop] = useState(0);
  const [left, setLeft] = useState(0);
  const handleChat = (e) => {
    e.preventDefault();
    const chatname = e.target.getAttribute("data-id");
    console.log(`Chatname: ${chatname}`);
    if (!chatname || !chat_content[chatname]) {
      console.error(`Invalid or missing chatname: ${chatname}`);
      return;
    }
    const chat = chat_content[chatname];
    if (chat) {
      setElementId(`workflow-chat${chatname}-${new Date().getTime()}`);
      setQuestion(chat.question);
      setTop(e.clientY);
      setLeft(e.clientX);
    }
  };

  const handleChatClose = () => {
    setElementId("");
    setQuestion("");
  };

  return { question, element_id, top, left, handleChat, handleChatClose };
};

export default UseChatBox;
