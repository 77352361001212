import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import FooterLogo from "../images/footer_logo.svg";
import { Link } from "react-router-dom";

function Footer() {
    return (
      <footer>
        <Container fluid="xxl" className="desktop-only">
          <Row>
            <Col className="text-left">
              <Link to="/">
                <img className="footer-logo" src={FooterLogo} alt="logo" />
              </Link>
            </Col>
            <Col className="text-center">
            <h6 className="copyright-text">
              © {new Date().getFullYear()}, Ept AI, Inc.
            </h6>

            </Col>
            <Col className="text-right">
              <ul className="footer-nav">
                <li>
                  <a href="https://www.ept.ai/privacy/">Privacy</a>
                </li>
                <li>
                  <a href="https://www.ept.ai/terms/">Terms & conditions</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </footer>
    );
}

export default Footer;